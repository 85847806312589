import { css } from '@emotion/core';
import * as React from 'react';
import Helmet from 'react-helmet';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import Wrapper from '../components/Wrapper';
import AboutMe1 from '../content/about/haztares-about-1.jpeg';
import IndexLayout from '../layouts';
import { inner, outer, SiteHeader, SiteMain } from '../styles/shared';
import { NoImage, PostFull, PostFullHeader } from '../templates/post';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
  .img-1 {
    text-align: center;
    margin-bottom: 1.5em;
    img {
      margin-bottom: 0px;
    }
  }
  .img-des {
    font-size: 14px;
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            {/* <PostFullTitle>About</PostFullTitle> */}
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>
                Xin chào các bạn mình là <b>Nguyễn Tấn Mỹ</b> có thể gọi là mình <b>Haz</b>.<br />Mình sinh năm 1994, quê gốc mình ở <b>Quảng Ngãi</b>, hộ khẩu mình thì ở <b>Bà Rịa - Vũng Tàu</b> và hiện giờ mình đang thường trú ở <b>Sài Gòn</b> nè, hihi :P.
              </p>
              <div className="img-1">
                <img src={AboutMe1} title="haztares" />
                <span className="img-des">Mình nè thấy bảnh trai hem :))</span>
              </div>

              <p>
                Mình là cựu sinh viên của trường "Đại Học Khoa Học Tự Nhiên TP.HCM". Mình học hệ cao đẳng của trường thôi, nên năm 2015 mình đã bắt đầu đi làm
                cho đến nay. Trải qua nhiều công ty khác nhau và giờ thì mình đang là thành viên của cty <a target="_blank" href="https://www.designveloper.com/" ><b>DSV</b></a> nè.
              </p>
              <p>
                Sở thích của mình thì thích nhiều thứ lắm: <i>Game, đọc sách, cà-phê, nấu ăn,... và giờ tập tành viết bờ-lốc nữa nè.</i>
              </p>
              <p>
                Về lý do mình đến với ngành lập trình này chắc có lẻ là do mình thích chơi game =]], năm lớp 2 thì mình đã biết đến với máy tính vào 1 lần mình
                đi học về thấy có tiệm nét ghé vào làm nhẹ 1 ván half-life. Và kể từ đó thì thỉnh thoảng ăn những trận đòn vì trốn học chơi game. Kỷ niệm mình nhớ nhất
                là chơi game và để bị mất xe đạp :(( lúc đó không dám về nhà luôn!!!. Đến năm lớp 12 mình vẫn chưa xác định được tương lai mình sẽ chọn ngành nào để học và rồi mình
                quyết định chọn ngành IT vì đơn giản là đc lên SG chơi game mà ko bị những trận đòn của ba nữa =]]].
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      {/* <Footer /> */}
    </Wrapper>
  </IndexLayout>
);

export default About;
